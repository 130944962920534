import React from "react";
import {
    MenuItem,
    Menu,
    TextField,
    Tooltip,
    Typography,
    Grid2,
} from "@mui/material";
import { IconCaretRightFilled } from "@tabler/icons-react";

export default function LabelMenu({
    contextMenu,

    labelMenuAnchorEl,
    setLabelMenuAnchorEl,
    state,
    setState,
}) {
    return (
        <MenuItem
            disabled={!contextMenu.shape}
            onMouseEnter={(e) => setLabelMenuAnchorEl(e.currentTarget)}
            onMouseLeave={(e) => setLabelMenuAnchorEl(null)}
        >
            <Grid2
                container
                sx={{ width: "100%" }}
                display={"flex"}
                alignItems={"center"}
            >
                <Grid2 size={11}>
                    <Typography>Label</Typography>
                </Grid2>{" "}
                <Grid2 size={1}>
                    {contextMenu.shape && contextMenu.shape.border && (
                        <IconCaretRightFilled width="10px" height="10px" />
                    )}
                </Grid2>
            </Grid2>
            <Tooltip>
                <span>
                    <Menu
                        disabled={!contextMenu.shape}
                        anchorEl={labelMenuAnchorEl}
                        open={Boolean(labelMenuAnchorEl)}
                        onClose={() => setLabelMenuAnchorEl(null)}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem
                            onClick={(e) =>
                                setState({
                                    ...state,
                                    shapes: state.shapes.map((shape) => {
                                        return shape.id === contextMenu.shape.id
                                            ? {
                                                  ...shape,
                                                  label: "",
                                              }
                                            : shape;
                                    }),
                                })
                            }
                        >
                            <TextField
                                inputProps={{ maxLength: 2 }}
                                onChange={(e) => {
                                    if (e.target.value.length > 2) {
                                        return;
                                    }
                                    setState({
                                        ...state,
                                        shapes: state.shapes.map((shape) => {
                                            return shape.id ===
                                                contextMenu.shape.id
                                                ? {
                                                      ...shape,
                                                      label: e.target.value,
                                                  }
                                                : shape;
                                        }),
                                    });
                                }}
                            />
                        </MenuItem>
                    </Menu>
                </span>
            </Tooltip>
        </MenuItem>
    );
}
