import React, { useState } from "react";
import { signIn } from "aws-amplify/auth";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { UserContext } from "../../UserContext";
import CliprIcon from "../../assets/clipr-icon.png";

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { user, setUser } = React.useContext(UserContext);
    const [formState, setFormState] = useState({
        isSigningIn: false,
        didSignIn: false,
        signInError: false,
    });

    if (user.isAuthorized) {
        window.location.href = "/account";
    }

    const handleSignIn = () => {
        setFormState({ ...formState, isSigningIn: true });
        signIn({
            username: email,
            password: password,
        })
            .then((u) => {
                setUser({
                    ...user,
                    cognito: u,
                    isAuthorized: true,
                    isAuthorizing: false,
                });
                setFormState({ ...formState, didSignIn: true });
                window.location.href = "/account";
            })
            .catch((error) => {
                setUser({ ...user, isAuthorizing: false });
                setFormState({
                    ...formState,
                    signInError: true,
                    isSigningIn: false,
                });
            });
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSignIn();
        }
    };

    return (
        <Box
            display="flex"
            marginTop="5%"
            flexDirection="column"
            alignItems="center"
            style={{ width: "100%", maxWidth: "100vw", overflowX: "hidden" }}
        >
            {formState.didSignIn ? (
                <CircularProgress />
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingTop="1vh"
                    paddingBottom="3vh"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        marginY="5%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            alt=""
                            src={CliprIcon}
                            style={{
                                width: "100px",
                                height: "100px",
                                marginRight: "4px",
                            }}
                        />
                        <Typography
                            style={{
                                textAlign: "center",
                                fontSize: "50px",
                                fontWeight: "bold",
                            }}
                        >
                            clipr
                        </Typography>
                    </Box>
                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "25px",
                        }}
                    >
                        Sign In
                    </Typography>
                    <Box
                        marginTop="2%"
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <TextField
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: "80vw", maxWidth: "400px" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                        <TextField
                            label="Password"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ width: "80vw", maxWidth: "400px" }}
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                        {formState.signInError && (
                            <Alert severity="error">
                                Invalid email or password
                            </Alert>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%">
                        {formState.isSigningIn ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    margin: "10px",
                                    width: "80vw",
                                    maxWidth: "400px",
                                }}
                                onClick={handleSignIn}
                                onKeyDown={handleKeyDown}
                            >
                                Continue
                            </Button>
                        )}
                    </Box>

                    <Typography
                        style={{
                            textAlign: "center",
                            fontSize: "16px",
                            marginTop: "1vh",
                        }}
                    >
                        Don't have an account?{" "}
                        <a
                            href="/sign-up"
                            style={{ color: "#095E89", textDecoration: "none" }}
                        >
                            Sign Up
                        </a>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SignIn;
