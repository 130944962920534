import { v4 as uuidv4 } from "uuid";

const handleMouseDown = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    editingMode,
    fieldDimensions,
    lasooSelection,
    setLasooSelection,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) return;
    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    if (editingMode === "addingIcon") {
        const lastShapes = state.shapes;
        const x = (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width);
        const y =
            (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height);
        setUndoStack([...undoStack, lastShapes]);
        setRedoStack([]);
        setState({
            ...state,
            shapes: [
                ...state.shapes,
                {
                    shape: "circle",
                    id: uuidv4(),
                    selected: false,
                    x,
                    y,
                    fill: "grey",
                    radius: (1.25 * fieldDimensions.width) / (65 * 2),
                },
            ],
        });
    } else if (editingMode === "drawing") {
        const lastShapes = state.shapes;
        isDrawing.current = editingMode === "drawing";
        setUndoStack([...undoStack, lastShapes]);
        setRedoStack([]);
        setState({
            ...state,

            shapes: [
                ...state.shapes,
                {
                    shape: "line",
                    id: uuidv4(),
                    selected: false,
                    fill: "grey",
                    radius: fieldDimensions.height * (0.25 / 150),
                    points: [
                        (mousePos.x - stagePosition.x) *
                            (65 / fieldDimensions.width),
                        (mousePos.y - stagePosition.y) *
                            (150 / fieldDimensions.height),
                    ],
                },
            ],
        });
    } else if (editingMode === "addingLine") {
        if (isAddingLine.current === true) {
            console.log("adding line current true");
            let lastLine = state.shapes[state.shapes.length - 1];
            lastLine.points = lastLine.points.concat([
                (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width),
                (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height),
            ]);
            state.shapes.splice(state.shapes.length - 1, 1, lastLine);
            setState({
                ...state,
                shapes: state.shapes,
            });
        } else {
            const lastShapes = state.shapes;
            isAddingLine.current = true;
            setUndoStack([...undoStack, lastShapes]);
            setRedoStack([]);
            setState({
                ...state,

                shapes: [
                    ...state.shapes,
                    {
                        shape: "line",
                        variant: state.variant,
                        id: uuidv4(),
                        selected: false,
                        fill: "grey",
                        radius: fieldDimensions.height * (0.25 / 150),
                        points: [
                            (mousePos.x - stagePosition.x) *
                                (65 / fieldDimensions.width),
                            (mousePos.y - stagePosition.y) *
                                (150 / fieldDimensions.height),
                        ],
                    },
                ],
            });
        }
    } else if (editingMode === "selecting") {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        setLasooSelection({
            isSelecting: true,
            startPos: { x, y },
            selectionRect: { x, y, width: 0, height: 0 },
        });
    }
};

const handleMouseMove = ({
    e,
    state,
    setState,
    isDrawing,
    isAddingLine,
    hoveredId,
    setHoveredId,
    editingMode,
    fieldDimensions,
    setCirclePosition,
    setIsShapeVisible,
    lasooSelection,
    setLasooSelection,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) {
        return;
    }

    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const stagePosition = stage.position();
    const shape = stage.getIntersection(stage.getPointerPosition());
    if (shape && shape.id() !== hoveredId) {
        setHoveredId(shape.id());
    } else if (!shape && hoveredId) {
        setHoveredId(null);
    }

    if (editingMode === "addingIcon") {
        setCirclePosition({
            x: mousePos.x - stagePosition.x,
            y: mousePos.y - stagePosition.y,
        });
        setIsShapeVisible(true);
    } else if (editingMode === "drawing") {
        if (!isDrawing.current) {
            return;
        }
        let lastLine = state.shapes[state.shapes.length - 1];
        lastLine.points = lastLine.points.concat([
            (mousePos.x - stagePosition.x) * (65 / fieldDimensions.width),
            (mousePos.y - stagePosition.y) * (150 / fieldDimensions.height),
        ]);
        state.shapes.splice(state.shapes.length - 1, 1, lastLine);
        setState({
            ...state,
            shapes: state.shapes,
        });
    } else if (editingMode === "selecting" && lasooSelection.isSelecting) {
        const x = mousePos.x - stagePosition.x;
        const y = mousePos.y - stagePosition.y;
        const newWidth = x - lasooSelection.startPos.x;
        const newHeight = y - lasooSelection.startPos.y;
        setLasooSelection({
            ...lasooSelection,
            selectionRect: {
                x: newWidth < 0 ? x : lasooSelection.startPos.x,
                y: newHeight < 0 ? y : lasooSelection.startPos.y,
                width: Math.abs(newWidth),
                height: Math.abs(newHeight),
            },
        });
    }
    return;
};

const handleMouseUp = ({
    e,
    state,
    setState,
    isDrawing,
    editingMode,
    lasooSelection,
    setLasooSelection,
    fieldDimensions,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) return;

    if (state.editing && editingMode === "selecting") {
        if (!lasooSelection.isSelecting) {
            const stage = e.target.getStage();
            const shape = stage.getIntersection(stage.getPointerPosition());
            if (!shape || !shape.id()) {
                setState({
                    ...state,
                    shapes: state.shapes.map((s) => {
                        return { ...s, selected: false };
                    }),
                });
            }
        } else {
            const { x, y, width, height } = lasooSelection.selectionRect;
            const scaledX = x * (65 / fieldDimensions.width);
            const scaledY = y * (150 / fieldDimensions.height);
            const scaledWidth = width * (65 / fieldDimensions.width);
            const scaledHeight = height * (150 / fieldDimensions.height);

            const selectedShapes = state.shapes.filter((shape) => {
                if (shape.shape === "circle" || shape.shape === "rect") {
                    return (
                        shape.x > scaledX &&
                        shape.x < scaledX + scaledWidth &&
                        shape.y > scaledY &&
                        shape.y < scaledY + scaledHeight
                    );
                } else if (shape.shape === "line") {
                    return shape.points.some(
                        (point, index) =>
                            index % 2 === 0 &&
                            point > scaledX &&
                            point < scaledX + scaledWidth &&
                            shape.points[index + 1] > scaledY &&
                            shape.points[index + 1] < scaledY + scaledHeight
                    );
                } else {
                    return false; // should never reach here
                }
            });
            setState({
                ...state,
                shapes: state.shapes.map((shape) => {
                    return {
                        ...shape,
                        selected: selectedShapes.some(
                            (selectedShape) => selectedShape.id === shape.id
                        ),
                    };
                }),
            });
        }
        setLasooSelection({
            startPos: null,
            isSelecting: false,
            selectionRect: null,
        });
    } else if (state.editing && editingMode === "drawing") {
        isDrawing.current = false;
        setState({
            ...state,
        });
    }
};

export { handleMouseDown, handleMouseMove, handleMouseUp };
