import {
    TextField,
    Box,
    ButtonGroup,
    Tooltip,
    Button,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Field from "./Field";
import EditMenu from "./EditMenu";
import Grid from "@mui/material/Grid2";
import { IconEdit, IconDeviceFloppy, IconTrash } from "@tabler/icons-react";

const PlayDesigner = () => {
    const [state, setState] = useState({
        editing: false,
        group: "Offense",
        formation: "",
        play: "",
        vsFormation: "",
        vsPlay: "",
        shapes: [],
        editingMode: "selecting", // selecting, drawing, addingIcon
        cursor: "default",
    });
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const isAddingLine = React.useRef(false);
    const [previewedShape, setPreviewedShape] = useState([]);
    const isDrawing = React.useRef(false);
    const anchoredPreviewedLine = React.useRef(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [clipboard, setClipboard] = useState([]);
    const [losLocation, setLosLocation] = useState(55);
    const direction = React.useRef("north");

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="35px"
            gap="1vh"
            paddingBottom="250px"
        >
            {state.editing && ( // Editing Menu
                <Box
                    width={{
                        xs: "90vw",
                        sm: "600px",
                        md: "900px",
                        lg: "1200px",
                        xl: "1500px",
                    }}
                >
                    <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        width={{
                            xs: "90vw",
                            sm: "600px",
                            md: "900px",
                            lg: "1200px",
                            xl: "1500px",
                        }}
                    >
                        <Grid size={1}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                justifyContent="left"
                                height="56px"
                            >
                                <ButtonGroup style={{ height: "100%" }}>
                                    <Button variant="contained" color="success">
                                        <IconDeviceFloppy />
                                    </Button>
                                    <Button color="error">
                                        <IconTrash
                                            onClick={(e) => {
                                                setState({
                                                    ...state,
                                                    editing: false,
                                                    shapes: undoStack[0].shapes,
                                                });
                                            }}
                                        />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                        <Grid size={2.75}>
                            <TextField
                                label="Formation"
                                variant="outlined"
                                value={state.formation}
                                sx={{ width: "90%" }}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        formation: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid size={2.75}>
                            <TextField
                                label="Play"
                                variant="outlined"
                                value={state.play}
                                sx={{ width: "90%" }}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        play: e.target.value,
                                    });
                                }}
                            />
                        </Grid>{" "}
                        <Grid size={2.75}>
                            <TextField
                                label="Opponent Formation"
                                variant="outlined"
                                value={state.vsFormation}
                                sx={{ width: "90%" }}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        vsFormation: e.target.value,
                                    });
                                }}
                            />
                        </Grid>{" "}
                        <Grid size={2.75}>
                            <TextField
                                label="Opponent Play"
                                variant="outlined"
                                value={state.vsPlay}
                                sx={{ width: "90%" }}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        vsPlay: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!state.editing && (
                <>
                    {" "}
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width={{
                            xs: "90vw",
                            sm: "600px",
                            md: "900px",
                            lg: "1200px",
                            xl: "1500px",
                        }}
                        height="35px"
                    >
                        <Grid size={3} style={{ marginBottom: "10vh" }}>
                            <ButtonGroup sx={{ height: "100%", width: "80%" }}>
                                <Tooltip title="Edit">
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                border: "0.5px solid black",
                                            }}
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    editing: true,
                                                })
                                            }
                                        >
                                            <IconEdit />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </ButtonGroup>
                        </Grid>
                        <Grid size={9}>
                            <Typography variant="h5">
                                {`${state.formation} ${state.play} ${
                                    (state.vsFormation || state.vsPlay) && "vs"
                                } ${state.vsFormation} ${state.vsPlay}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            <Field
                editingMode={state.editingMode}
                state={state}
                setState={setState}
                isAddingLine={isAddingLine}
                undoStack={undoStack}
                setUndoStack={setUndoStack}
                redoStack={redoStack}
                setRedoStack={setRedoStack}
                previewedShape={previewedShape}
                setPreviewedShape={setPreviewedShape}
                isDrawing={isDrawing}
                anchoredPreviewedLine={anchoredPreviewedLine}
                contextMenu={contextMenu}
                setContextMenu={setContextMenu}
                clipboard={clipboard}
                setClipboard={setClipboard}
                losLocation={losLocation}
                setLosLocation={setLosLocation}
                direction={direction}
            />{" "}
            <EditMenu
                parentState={state}
                setParentState={setState}
                undoStack={undoStack}
                setUndoStack={setUndoStack}
                redoStack={redoStack}
                setRedoStack={setRedoStack}
                isAddingLine={isAddingLine}
                previewedShape={previewedShape}
                setPreviewedShape={setPreviewedShape}
                isDrawing={isDrawing}
                anchoredPreviewedLine={anchoredPreviewedLine}
                losLocation={losLocation}
                setLosLocation={setLosLocation}
                direction={direction}
            />
            <Box paddingTop="10px">
                <TextField
                    label="Notes"
                    sx={{
                        width: {
                            xs: "90vw",
                            sm: "600px",
                            md: "900px",
                            lg: "1200px",
                            xl: "1500px",
                        },
                    }}
                    variant="outlined"
                    multiline
                />
            </Box>
        </Box>
    );
};
export default PlayDesigner;
