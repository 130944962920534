import React from 'react';
import { Typography, Box, CircularProgress, Alert, AlertTitle, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { UserContext } from '../../UserContext';
import CliprIcon from '../../assets/clipr-icon.png';
import { MuiTelInput } from 'mui-tel-input';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

const Account = () => {
    const { user, setUser } = React.useContext(UserContext);

    if (!user.isAuthorizing && !user.isAuthorized) {
        window.location.href = '/sign-in';
    }

    const [formState, setFormState] = React.useState({
        isUpdating: false,
        isUpdated: false,
        email: user.attributes.email,
        firstName: user.attributes.firstName,
        lastName: user.attributes.lastName,
        phoneNumber: user.attributes.phoneNumber
    });

    const handleChange = (e) => {
        const name = e.target ? e.target.name : 'phoneNumber';
        const value = e.target ? e.target.value : e;
        name !== 'email' && setFormState({ ...formState, [name]: value });

    };

    const handleUpdateAccount = async () => {
        setFormState({ ...formState, isUpdating: true });
        const { firstName, lastName, phoneNumber } = formState;
        const restOperation = post({
            apiName: "user_management",
            path: "/users/update",
            options: {
                queryParams: { userId: user.attributes.id },
                body: { firstName, lastName, phoneNumber },
                headers: {
                    "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
            }
        })
        const response = await restOperation.response;
        const { body } = await response;
        const json = await body.json();
        setFormState({ ...formState, isUpdating: false, isUpdated: true });
        setUser({ ...user, attributes: json });
    };


    return (
        <Box display="flex" marginTop="5%" flexDirection="column" alignItems="center" style={{ width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="1vh"
                paddingBottom="3vh"
            >
                <Box display="flex" flexDirection="row" marginY="5%" alignItems="center" justifyContent="center">
                    <img alt="" src={CliprIcon} style={{ width: '100px', height: '100px', marginRight: "4px" }} />
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontSize: '50px',
                            fontWeight: "bold"
                        }}
                    >
                        clipr
                    </Typography>
                </Box>
                {user.isAuthorizing || user.isFetchingAttributes ? <CircularProgress /> :
                    <>
                        {!user.attributes.isAccountComplete &&
                            <Alert severity="warning" style={{ width: '80vw', maxWidth: '400px' }}>
                                <AlertTitle>Account Sign Up Incomplete</AlertTitle>
                                Please complete your account sign up by providing the following information.
                            </Alert>
                        }
                        <Box marginTop="2%" display="flex" justifyContent="center" width="100%">
                            <TextField
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                value={formState.email || user.attributes.email || ''}
                                name="email"
                                style={{ width: '80vw', maxWidth: '400px' }}
                            />
                        </Box>
                        <Box marginTop="2%" display="flex" justifyContent="center" width="100%">
                            <TextField
                                label="First Name"
                                variant="outlined"
                                margin="normal"
                                value={formState.firstName || user.attributes.firstName || ''}
                                name="firstName"
                                onChange={handleChange}
                                style={{ width: '39.5vw', maxWidth: '200px', paddingRight: '0.5vw' }}
                            />
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                margin="normal"
                                value={formState.lastName || user.attributes.lastName || ''}
                                name="lastName"
                                onChange={handleChange}
                                style={{ width: '39.5vw', maxWidth: '200px', paddingLeft: '0.5vw' }}
                            />
                        </Box>
                        <Box marginTop="2%" display="flex" justifyContent="center" width="100%">
                            <MuiTelInput
                                label="Phone Number"
                                variant="outlined"
                                margin="normal"
                                value={formState.phoneNumber || user.attributes.phoneNumber || ''}
                                onChange={handleChange}
                                defaultCountry="CA"
                                style={{ width: '80vw', maxWidth: '400px' }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" width="100%">
                            {formState.isUpdating ?
                                <CircularProgress />
                                :
                                <Button
                                    variant="contained"
                                    style={{ margin: "10px", width: '80vw', maxWidth: '400px' }}
                                    onClick={handleUpdateAccount}
                                >
                                    Update
                                </Button>}
                        </Box>
                    </>}
            </Box>
        </Box >
    );
}

export default Account;
