import { Circle, Rect, Line, Arrow } from "react-konva";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { handleDragStart, handleDragMove, handleDragEnd } from "./handleDrag";
// import SelectionBox from "./SelectionBox";

const Drawing = ({
    fieldDimensions,
    state,
    setState,
    hoveredId,
    editingMode,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    const theme = useTheme();
    const [draggingShapeId, setDraggingShapeId] = useState(null); // Track which shape is being dragged
    const [dragOrigin, setDragOrigin] = useState(null);

    function onClick({ e, shape }) {
        if (e.evt.ctrlKey) {
            shape.selected = !shape.selected;
            setState({
                ...state,
                shapes: state.shapes.map((s) => {
                    if (s.id === shape.id) {
                        return shape;
                    }
                    return s;
                }),
            });
        } else {
            setState({
                ...state,
                shapes: state.shapes.map((s) => {
                    if (s.id === shape.id) {
                        return { ...shape, selected: true };
                    }
                    return { ...s, selected: false };
                }),
            });
        }
    }

    const renderShape = ({
        shape,
        fill,
        draggable,
        undoStack,
        setUndoStack,
        redoStack,
        setRedoStack,
    }) => {
        if (shape.shape === "circle") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);

            return (
                <>
                    <Circle
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                setRedoStack,
                                redoStack,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        radius={shape.radius}
                        fill={
                            (state.editing && shape.selected) ||
                            shape.id === hoveredId
                                ? theme.palette.primary.main
                                : fill
                        }
                        onClick={(e) => onClick({ e, shape })}
                    />
                    {/* {state.editing && shape.selected && (
                        <SelectionBox
                            shape={shape}
                            fieldDimensions={fieldDimensions}
                        />
                    )} */}
                </>
            );
        } else if (shape.shape === "rect") {
            const x = fieldDimensions.width * (shape.x / 65);
            const y = fieldDimensions.height * (shape.y / 150);
            return (
                <>
                    <Rect
                        key={shape.id}
                        id={shape.id}
                        x={x}
                        y={y}
                        draggable={
                            state.editing &&
                            editingMode === "selecting" &&
                            draggable
                        }
                        onDragStart={(e) =>
                            handleDragStart({
                                e,
                                state,
                                setState,
                                setDragOrigin,
                                shape,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        onDragMove={(e) =>
                            handleDragMove({
                                e,
                                shape,
                                dragOrigin,
                                draggingShapeId,
                                setDragOrigin,
                                fieldDimensions,
                                state,
                                setState,
                                undoStack,
                                setUndoStack,
                            })
                        }
                        onDragEnd={() =>
                            handleDragEnd({
                                state,
                                setState,
                                setDragOrigin,
                                setDraggingShapeId,
                                undoStack,
                                setUndoStack,
                                redoStack,
                                setRedoStack,
                            })
                        }
                        width={shape.width}
                        height={shape.height}
                        fill={
                            (state.editing && shape.selected) ||
                            shape.id === hoveredId
                                ? theme.palette.primary.main
                                : fill
                        }
                        onClick={(e) => onClick({ e, shape })}
                    />
                </>
            );
        } else if (shape.shape === "line") {
            const points = shape.points.map((point, i) => {
                if (i % 2 === 0) {
                    // x coordinate
                    return fieldDimensions.width * (point / 65);
                } else {
                    // y coordinate
                    return fieldDimensions.height * (point / 150);
                }
            });

            let SHAPE = Line;
            if (shape.variant === "arrow" || shape.variant === "dashedArrorw") {
                SHAPE = Arrow;
            }
            return (
                <SHAPE
                    key={shape.id}
                    id={shape.id}
                    draggable={
                        state.editing &&
                        editingMode === "selecting" &&
                        draggable
                    }
                    dash={
                        shape.variant === "dashed" && [
                            shape.radius * 1,
                            shape.radius * 2,
                        ]
                    }
                    points={points}
                    stroke={
                        (state.editing && shape.selected) ||
                        shape.id === hoveredId
                            ? theme.palette.primary.main
                            : fill
                    }
                    onClick={(e) => onClick({ e, shape })}
                    onDragStart={(e) =>
                        handleDragStart({
                            e,
                            state,
                            setState,
                            setDragOrigin,
                            shape,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                            redoStack,
                            setRedoStack,
                        })
                    }
                    onDragMove={(e) =>
                        handleDragMove({
                            e,
                            shape,
                            dragOrigin,
                            draggingShapeId,
                            setDragOrigin,
                            fieldDimensions,
                            state,
                            setState,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    onDragEnd={() =>
                        handleDragEnd({
                            state,
                            setState,
                            setDragOrigin,
                            setDraggingShapeId,
                            undoStack,
                            setUndoStack,
                        })
                    }
                    strokeWidth={shape.radius}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation="source-over"
                />
            );
        } else {
            return null;
        }
    };
    return (
        <>
            {state.shapes.map((shape) =>
                renderShape({
                    shape,
                    fill: shape.fill,
                    draggable: shape.selected || shape.id === hoveredId,
                    undoStack,
                    setUndoStack,
                    redoStack,
                    setRedoStack,
                })
            )}
        </>
    );
};

export default Drawing;
