const handleDragStart = ({
    e,
    state,
    setState,
    setDragOrigin,
    shape,
    setDraggingShapeId,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) return;

    setUndoStack([...undoStack, state.shapes]);
    setRedoStack([]);

    setDragOrigin({ x: e.target.x(), y: e.target.y() });
    setDraggingShapeId(shape.id);
};

const handleDragMove = ({
    e,
    dragOrigin,
    draggingShapeId,
    setDragOrigin,
    fieldDimensions,
    state,
    setState,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) return;

    if (!draggingShapeId || !dragOrigin) return;

    // Delay the execution of handleDragMove to ensure undoStack is updated
    const dx = e.target.x() - dragOrigin.x;
    const dy = e.target.y() - dragOrigin.y;
    const scaledDx = (dx / fieldDimensions.width) * 65;
    const scaledDy = (dy / fieldDimensions.height) * 150;

    setState((prevState) => ({
        ...state,
        shapes: prevState.shapes.map((s) => {
            if (s.selected || s.id === draggingShapeId) {
                if (s.shape === "circle" || s.shape === "rect") {
                    return {
                        ...s,
                        x: s.x + scaledDx,
                        y: s.y + scaledDy,
                    };
                } else if (s.shape === "line") {
                    if (s.id === draggingShapeId) {
                        return s;
                    } else {
                        return {
                            ...s,
                            points: s.points.map((p, i) => {
                                if (i % 2 === 0) {
                                    return p + scaledDx;
                                }
                                return p + scaledDy;
                            }),
                        };
                    }
                }
            }
            return s;
        }),
    }));
    setDragOrigin({ x: e.target.x(), y: e.target.y() });
};

const handleDragEnd = ({
    state,
    setState,
    setDragOrigin,
    setDraggingShapeId,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
}) => {
    if (!state.editing) return;

    setDraggingShapeId(null);
    setDragOrigin(null);
};

export { handleDragStart, handleDragMove, handleDragEnd };
