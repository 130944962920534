import { TextField, Box } from "@mui/material";
import React, { useState } from "react";
import Field from "./Field";
import EditMenu from "./EditMenu";
import Grid from "@mui/material/Grid2";

const PlayDesigner = () => {
    const [state, setState] = useState({
        editing: false,
        group: "Offense",
        formation: "<Formation>",
        play: "<Play>",
        vsFormation: "<Opp. Formation>",
        vsPlay: "<Opp. Play>",
        shapes: [],
        editingMode: "selecting", // selecting, drawing, addingIcon
        cursor: "default",
    });
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const isAddingLine = React.useRef(false);

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                paddingTop="35px"
                gap="1vh"
            >
                <EditMenu
                    parentState={state}
                    setParentState={setState}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    redoStack={redoStack}
                    setRedoStack={setRedoStack}
                />

                <Field
                    editingMode={state.editingMode}
                    state={state}
                    setState={setState}
                    isAddingLine={isAddingLine}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    redoStack={redoStack}
                    setRedoStack={setRedoStack}
                />
                {state.editing && ( // Editing Menu
                    <Box width="70vw">
                        <Grid
                            container
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            width="70vw"
                        >
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Formation"
                                    variant="outlined"
                                    value={state.formation}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            formation: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Play"
                                    variant="outlined"
                                    value={state.play}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            play: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>{" "}
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Opponent Formation"
                                    variant="outlined"
                                    value={state.vsFormation}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            vsFormation: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>{" "}
                            <Grid size={3} width="24%">
                                <TextField
                                    label="Opponent Play"
                                    variant="outlined"
                                    value={state.vsPlay}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            vsPlay: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box width="70vw" height="100px">
                    <TextField
                        fullWidth
                        height="100px"
                        multiline
                        disabled={!state.editing}
                        label="Notes"
                    />
                </Box>
            </Box>{" "}
        </Box>
    );
};
export default PlayDesigner;
