import React, { useState } from "react";
import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import {
    CircularProgress,
    Typography,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Button,
    Box,
    TextField,
    Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import GroupIcon from "@mui/icons-material/Group";
import { useEffect } from "react";
import { useRef } from "react";

export default function OrganizationGroups({ tenantId }) {
    function CreateGroup({
        tenantId,
        parentFormState,
        setParentFormState,
        innerRef,
    }) {
        const [formState, setFormState] = useState({
            groupName: "",
            isSubmitting: false,
            isSubmitted: false,
        });

        const handleSubmit = async () => {
            setFormState({ ...formState, isSubmitting: true });
            const restOperation = post({
                apiName: "user_management",
                path: "/tenant_groups/create",
                options: {
                    queryParams: { tenantId },
                    body: {
                        name: formState.groupName,
                    },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const response = await restOperation.response;
            await response.body.json();
            setFormState({
                ...formState,
                isSubmitting: false,
                isSubmitted: true,
            });
            setParentFormState({
                ...parentFormState,
                isCreating: false,
                isFetched: false,
            });
        };

        return (
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.5)"
                zIndex="1300"
            >
                <div ref={innerRef}>
                    <Card>
                        <CardContent>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                                style={{ width: "80vw", maxWidth: "400px" }}
                            >
                                <CardHeader title={<b>Create Group</b>} />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CardActions>
                                    <TextField
                                        label="Group Name"
                                        variant="outlined"
                                        margin="normal"
                                        value={formState.groupName}
                                        onChange={(e) =>
                                            setFormState({
                                                ...formState,
                                                groupName: e.target.value,
                                            })
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {formState.isSubmitting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button onClick={handleSubmit}>
                                            Confirm
                                        </Button>
                                    )}
                                </CardActions>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            </Box>
        );
    }

    const [groups, setGroups] = useState([]);
    const [formState, setFormState] = useState({
        isFetching: false,
        isFetched: false,
        isCreating: false,
    });

    const createGroupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                createGroupRef.current &&
                !createGroupRef.current.contains(event.target)
            ) {
                setFormState((prevState) => ({
                    ...prevState,
                    isCreating: false,
                }));
            }
        };

        if (formState.isCreating) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [formState.isCreating]);

    const fetchGroups = async () => {
        setFormState({ ...formState, isFetching: true });

        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/tenant_groups/list",
                options: {
                    queryParams: { tenantId },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setGroups(json);
        } catch (error) {
            console.error(error);
        }

        setFormState({ ...formState, isFetching: false, isFetched: true });
    };

    if (!formState.isFetching && !formState.isFetched) {
        fetchGroups();
    }

    return (
        <>
            {formState.isCreating && (
                <CreateGroup
                    tenantId={tenantId}
                    parentFormState={formState}
                    setParentFormState={setFormState}
                    innerRef={createGroupRef}
                />
            )}
            <Card sx={{ width: "100%", height: "600px" }}>
                <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid size={12}>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    paddingBottom="3vh"
                                >
                                    <Typography variant="h3">
                                        <b>groups</b>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container alignItems="center">
                        <Grid
                            size={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    onClick={() =>
                                        setFormState({
                                            ...formState,
                                            isCreating: true,
                                        })
                                    }
                                >
                                    Create
                                </Button>
                            </Box>
                        </Grid>
                        <Grid size={12} borderBottom="1px solid grey"></Grid>
                    </Grid>
                    {formState.isFetching && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            paddingTop="10px"
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {formState.isFetched && groups.length === 0 && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            paddingTop="10px"
                        >
                            <Typography variant="h5">
                                No groups found
                            </Typography>
                        </Box>
                    )}
                    {formState.isFetched && groups.length > 0 && (
                        <>
                            <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="2vh"
                            >
                                {groups.map((group, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                size={1}
                                                display="flex"
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                {<GroupIcon />}
                                            </Grid>
                                            <Grid
                                                item
                                                size={5}
                                                display="flex"
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                <Link
                                                    href={`/tenant/${tenantId}/group/${group.groupId}`}
                                                >
                                                    <Typography>
                                                        <b> {group.name}</b>
                                                    </Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
}
