import { useEffect } from "react";

const useResizeEffect = (setStageDimensions, setFieldDimensions) => {
    useEffect(() => {
        const updateStageAndFieldDimensions = () => {
            const width =
                window.innerWidth < 600
                    ? window.innerWidth * 0.9
                    : window.innerWidth < 900
                    ? 600
                    : window.innerWidth < 1200
                    ? 900
                    : window.innerWidth < 1500
                    ? 1200
                    : 1500;

            setStageDimensions({
                width: width,
                height: (width * 9) / 16,
            });
            setFieldDimensions({
                width: width,
                height: (width * 150) / 65,
            });
        };

        updateStageAndFieldDimensions();
        window.addEventListener("resize", updateStageAndFieldDimensions);

        return () =>
            window.removeEventListener("resize", updateStageAndFieldDimensions);
    }, [setStageDimensions, setFieldDimensions]);
};

export default useResizeEffect;
