import React, { useState, useRef, useEffect } from "react";
import {
    CircularProgress,
    Typography,
    Card,
    CardContent,
    Button,
    Box,
    TextField,
    CardHeader,
    CardActions,
    AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Alert from "@mui/material/Alert";

export default function OrganizationMembers({ tenant }) {
    function InviteUser({
        tenant,
        parentFormState,
        setParentFormState,
        innerRef,
    }) {
        const [formState, setFormState] = useState({
            email: "",
            isInviting: false,
            isInvited: false,
            didInviteFail: false,
        });

        const handleInvite = async () => {
            setFormState({ ...formState, isInviting: true });
            try {
                const restOperation = post({
                    apiName: "user_management",
                    path: "/tenant_users/invite",
                    options: {
                        body: {
                            email: formState.email,
                            tenantId: tenant.id,
                        },
                        headers: {
                            Authorization: (
                                await fetchAuthSession()
                            ).tokens?.idToken?.toString(),
                        },
                    },
                });
                await restOperation.response;
                setFormState({
                    ...formState,
                    isInviting: false,
                    isInvited: true,
                });
                setParentFormState({
                    ...parentFormState,
                    isInviting: false,
                    isFetched: false,
                });
            } catch (error) {
                setFormState({
                    ...formState,
                    isInviting: false,
                    didInviteFail: true,
                });
            }
        };

        return (
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.5)"
                zIndex="1300"
            >
                <div ref={innerRef}>
                    <Card>
                        <CardContent>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                                style={{ width: "80vw", maxWidth: "400px" }}
                            >
                                <CardHeader title={<b>Invite User</b>} />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CardActions>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        value={formState.email}
                                        onChange={(e) =>
                                            setFormState({
                                                ...formState,
                                                email: e.target.value,
                                            })
                                        }
                                        style={{ width: "70%" }}
                                    />
                                    {formState.isInviting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button onClick={handleInvite}>
                                            Confirm
                                        </Button>
                                    )}
                                </CardActions>
                            </Box>
                            {formState.didInviteFail && (
                                <Alert
                                    severity="error"
                                    style={{ width: "70vw", maxWidth: "400px" }}
                                >
                                    <AlertTitle>
                                        Failed to invite user
                                    </AlertTitle>
                                    Please confirm user has registered and you
                                    entered the correct email address.
                                </Alert>
                            )}
                        </CardContent>
                    </Card>
                </div>
            </Box>
        );
    }

    const [members, setMembers] = useState([]);
    const [formState, setFormState] = useState({
        isFetching: false,
        isFetched: false,
        didCopy: false,
        isInviting: false,
    });

    const inviteUserRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inviteUserRef.current &&
                !inviteUserRef.current.contains(event.target)
            ) {
                setFormState((prevState) => ({
                    ...prevState,
                    isInviting: false,
                }));
            }
        };

        if (formState.isInviting) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [formState.isInviting]);

    const handleConfirm = async (member) => {
        let newMembers = members.map((m) => {
            if (m.user.id === member.user.id) {
                m.isRegistering = true;
            }
            return m;
        });
        setMembers(newMembers);
        const restOperation = post({
            apiName: "user_management",
            path: "/tenant_users/confirm",
            options: {
                queryParams: {
                    tenantId: tenant.id,
                    userId: member.user.id,
                },
                headers: {
                    Authorization: (
                        await fetchAuthSession()
                    ).tokens?.idToken?.toString(),
                },
            },
        });
        const response = await restOperation.response;
        await response.body.json();
        newMembers = members.map((m) => {
            if (m.user.id === member.user.id) {
                m.isRegistrationConfirmed = true;
                m.isRegistering = false;
            }
            return m;
        });
        setMembers(newMembers);
    };

    const fetchMembers = async () => {
        setFormState((prevState) => ({ ...prevState, isFetching: true }));

        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/tenant_users/list",
                options: {
                    queryParams: { tenantId: tenant.id },
                    headers: {
                        Authorization: (
                            await fetchAuthSession()
                        ).tokens?.idToken?.toString(),
                    },
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            const newMembers = json.map((m) => {
                m.isRegistering = false;
                return m;
            });
            setMembers(newMembers);
        } catch (error) {
            console.error(error);
        }
        setFormState((prevState) => ({
            ...prevState,
            isFetching: false,
            isFetched: true,
        }));
    };

    if (!formState.isFetching && !formState.isFetched && tenant.id) {
        fetchMembers();
    }

    return (
        <>
            {formState.isInviting && (
                <InviteUser
                    tenant={tenant}
                    parentFormState={formState}
                    setParentFormState={setFormState}
                    innerRef={inviteUserRef}
                />
            )}

            <Card sx={{ width: "100%", height: "600px" }}>
                <CardContent>
                    <Box width="100%">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            paddingBottom="3vh"
                        >
                            <Typography variant="h3">
                                <b>members</b>
                            </Typography>

                            {tenant.id && (
                                <Grid
                                    container
                                    alignItems="center"
                                    width="100%"
                                >
                                    <Grid
                                        size={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box
                                            paddingY="1vh"
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="left"
                                            justifyContent="left"
                                        >
                                            <Button
                                                onClick={() =>
                                                    setFormState(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            isInviting: true,
                                                        })
                                                    )
                                                }
                                            >
                                                <Typography>
                                                    Invite by Email
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>

                                    <Grid
                                        size={12}
                                        borderBottom="1px solid grey"
                                        height="10px"
                                    ></Grid>
                                </Grid>
                            )}
                        </Box>

                        {formState.isFetching && (
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        {formState.isFetched && members.length === 0 && (
                            <Typography>No members found</Typography>
                        )}
                        {formState.isFetched && members.length > 0 && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                paddingTop="5px"
                                borderTop="1px solid grey"
                            >
                                {members.map((member, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                size={1}
                                                display="flex"
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                {
                                                    <HowToRegIcon
                                                        color={
                                                            member.isRegistrationConfirmed
                                                                ? "success"
                                                                : "error"
                                                        }
                                                    />
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                size={5}
                                                display="flex"
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                <Typography>
                                                    <b>
                                                        {" "}
                                                        {
                                                            member.user.lastName
                                                        },{" "}
                                                        {member.user.firstName}
                                                    </b>
                                                </Typography>
                                            </Grid>
                                            {!member.isRegistrationConfirmed &&
                                                !member.isRegistering && (
                                                    <Grid item size={4}>
                                                        <Button
                                                            onClick={() =>
                                                                handleConfirm(
                                                                    member
                                                                )
                                                            }
                                                        >
                                                            <Typography>
                                                                Confirm
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                )}
                                            {member.isRegistering && (
                                                <CircularProgress />
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}
