import { useEffect } from "react";

const useResizeEffect = (setStageDimensions, setFieldDimensions) => {
    useEffect(() => {
        const updateStageAndFieldDimensions = () => {
            setStageDimensions({
                width: window.innerWidth * 0.7,
                height: (window.innerWidth * 0.7 * 150) / 65,
            });
            setFieldDimensions({
                height: (window.innerWidth * 0.7 * 150) / 65,
                width: window.innerWidth * 0.7,
            });
        };

        updateStageAndFieldDimensions();
        window.addEventListener("resize", updateStageAndFieldDimensions);

        return () =>
            window.removeEventListener("resize", updateStageAndFieldDimensions);
    }, [setStageDimensions, setFieldDimensions]);
};

export default useResizeEffect;
