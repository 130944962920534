import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import { get, post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { UserContext } from '../../UserContext';

export default function OrganizationRegistration() {

    const { user } = React.useContext(UserContext);

    const [state, setState] = React.useState({
        organization: {},
        isFetching: false,
        isFetched: false,
        isRegistering: false,
        isRegistered: false,
    });

    const { tenantId, associationKey } = useParams();


    const fetchOrganization = async () => {
        setState({ ...state, isFetching: true });
        const restOperation = get({
            apiName: "user_management",
            path: `/tenants/get/${tenantId}`,
            options: {
                headers: {
                    "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
            }
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        setState({ ...state, organization: json, isFetching: false, isFetched: true });
    };


    if (!state.isFetching && !state.isFetched) {
        fetchOrganization();
    }


    const handleRegister = async () => {
        if (associationKey !== state.organization.associationKey) {
            window.location.href = '/account';
            return;
        }
        setState({ ...state, isRegistering: true });
        const restOperation = post({
            apiName: "user_management",
            path: "/tenant_users/register",
            options: {
                queryParams: {
                    tenantId, associationKey, userId: user.attributes.id
                },
                headers: {
                    "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
            }
        })
        const response = await restOperation.response;
        const { body } = response;
        await body.json();
        window.location.href = `/tenant/${tenantId}`;
    };



    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="4vh" spacing={2}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="4vh" gap="4vw">
                {state.isFetching && <CircularProgress />}

                {
                    state.isFetched && associationKey === state.organization.associationKey &&
                    <>
                        <Typography variant="h2">
                            {state.organization.name}
                        </Typography>
                        {!state.isRegistering && <Typography >
                            Press continue to register with {state.organization.name}
                        </Typography>}
                    </>
                }

                {
                    state.isFetched && associationKey !== state.organization.associationKey && !state.isRegistering &&
                    <>
                        <Typography variant="h2">
                            Invalid Registration URL
                        </Typography>
                        <Typography >
                            Please obtain a new registration url from your clipr administrator.
                        </Typography>
                    </>
                }
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
                {state.isRegistering && <CircularProgress />}
                {!state.isRegistered && !state.isFetching && !state.isRegistering &&
                    <Button
                        variant="contained"
                        style={{ margin: "10px", width: '80vw', maxWidth: '400px' }}
                        onClick={handleRegister}
                    >
                        {associationKey === state.organization.associationKey ? "Continue" : "Return to account"}
                    </Button>}
            </Box>
        </Box>
    );
}